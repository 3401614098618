
import MButton from "@/components/MButton.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { getCustomerPortalSessionUrl } from "@/api/stripe";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MButton
  }
})
export default class Unavailable extends Vue {
  get isOwnerAndUseStripe(): boolean {
    if (!store.state.tutor || !store.state.schoolConfig) {
      return false;
    }
    return (
      store.state.tutor.config.data.authority === "owner" &&
      store.state.schoolConfig.data.paymentType === "stripe"
    );
  }
  async redirectCustomerPortal() {
    if (!store.state.school) {
      return;
    }

    store.commit("START_LOADING", "");
    try {
      const url = await getCustomerPortalSessionUrl(
        store.state.school.ref.id,
        window.location.href
      );
      store.commit("END_LOADING");
      window.location.href = url;
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to get customer portal session url"
      );
    }
  }

  async signOut() {
    store.dispatch("signOut");
  }
}
