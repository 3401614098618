import firebase from "firebase/app";

export async function getCustomerPortalSessionUrl(
  schoolId: string,
  redirect?: string
): Promise<string> {
  const createCustomerPortalSession = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("create_customer_portal_session");

  const data: { school: string; redirect?: string } = {
    school: schoolId
  };
  if (redirect) {
    data.redirect = redirect;
  }

  const res = await createCustomerPortalSession(data);
  if (!res.data.url) {
    throw new Error("unexpected error occured");
  }
  return res.data.url;
}
